<template>
  <div class="card" v-if="update">
    <div
      v-if="update.post_extend.banner_medium"
      class="update-image"
      :style="backgroundImage(update.post_extend.banner_medium_url)"
    ></div>
    <div class="card__section update-author" v-if="update.author">
      <div class="grid-x grid-margin-x align-middle">
        <div class="cell shrink">
          <div
            class="update-author__image"
            :style="backgroundImage(update.author.avatar.url)"
          ></div>
        </div>
        <div class="cell auto">
          <span class="update-author__name">{{ update.author.name }}</span>
          <span class="update-author__function">{{
              update.author.function
            }}</span>
        </div>
        <div class="cell shrink">
          <span class="update-author__badge">
            <i class="icon-blog-time"></i>
            {{ update.created_at | formatDate }}</span
          >
        </div>
      </div>
    </div>
    <hr v-if="update.author"/>
    <div class="card__section">
      <h2>{{ update.title }}</h2>
      <div class="spacer--10"></div>
      <div v-html="update.content_html"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import backend from '@/backend'

export default {
  name: 'UpdateDetail',
  data () {
    return {
      id: null,
      update: null
    }
  },
  created () {
    backend
      .get(
        `${process.env.VUE_APP_URL}/blog/posts/abrnd/${this.$route.params.pathName}`
      )
      .then(response => {
        this.update = response.data
      })
  },
  methods: {
    backgroundImage (value) {
      const image = value.split(' ').join('%20')
      return 'background-image:url(' + image + ');'
    }
  },
  filters: {
    formatDate (value) {
      return moment(String(value)).format('DD MMMM YYYY')
    }
  }
}
</script>

<style scoped>
hr {
  border-color: $ ui-line-color;
  margin: 0;
}
</style>
