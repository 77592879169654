<template>
  <ul class="updates-list">
    <li v-for="(update, index) in updates" :key="index">
      <router-link
        :to="{ name: 'UpdatesDetail', params: { pathName: update.slug } }"
        class="card card__section"
      >
        <span class="updates-list__badge" v-if="update.version">{{
            update.version
          }}</span>
        <p class="updates-list__title">{{ update.title }}</p>
        <div
          class="updates-list__content"
          v-html="update.excerpt"
          v-if="update.excerpt"
        ></div>
      </router-link>
    </li>
  </ul>
</template>

<script>
import backend from '@/backend'

export default {
  name: 'UpdatesList',
  data () {
    return {
      updates: []
    }
  },
  created () {
    backend
      .get(`${process.env.VUE_APP_URL}/blog/posts/abrnd`)
      .then(response => {
        this.updates = response.data
      })
  }
}
</script>

<style lang="scss" scoped>
.updates-list {
  li {
    + li {
      margin-top: 20px;
    }
  }
}
</style>
